<template>
  <nh-dialog
    v-model="visible"
    title="任务详情"
    :before-close="handleClose"
    custom-class="custom-dialog_70"
    :track="{
      name_zh: `任务中心/任务管理/弹窗-详情`,
      trackName: $route.path
    }"
  >
    <div class="details-page">
      <div class="block">
        <div class="title">
          <h3>任务详情</h3>
        </div>
        <div class="body store_info">
          <el-row>
            <el-col
              v-for="(item, index) in StoreInfo"
              :key="index"
              :span="8"
            >
              <el-form label-width="120px" label-position="left">
                <el-form-item :label="item.label">
                  <span v-if="item.formatter"> {{ item.formatter(StoreData[item.prop]) }}</span>
                  <span v-else>{{ StoreData[item.prop] }}</span>
                  <span
                    v-if="item.prop === 'taskNo'&&
                      StoreData.important === IMPORTANT_ENUM_VALUE.URGENT"
                    class="task-urgent"
                  > 加急</span>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="block">
        <div class="title">
          <h3>任务明细</h3>
        </div>
        <div class="body">
          <el-table
            v-loading="loading.loadSubtasksPage"
            :data="tableData"
            border
            class="flex_1"
            size="small"
            :span-method="handleSpanMethod"
          >
            <nh-table-column
              v-for="item in TABLECOLUMN(StoreData.bizType)"
              :key="item.prop"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              show-overflow-tooltip
            >
              <template #default="scope">
                <div>
                  <span v-if="item.formatter">{{ item.formatter(scope.row[item.prop]) }}</span>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </div>
              </template>
            </nh-table-column>
          </el-table>
          <nh-pagination
            class="margin-top_normal"
            v-bind="pagination"
            @change="handlePaginationChange"
          />
        </div>
      </div>
    </div>
  </nh-dialog>
</template>
<script>
import loadingMixin from '@thales/loading';
import { StoreInfo, TABLECOLUMN } from './fileds';
import { subtasksPage } from '../api';
import {
  BIZ_TYPE_VALUE_ENUM, IMPORTANT_ENUM, IMPORTANT_ENUM_VALUE, SUBTASK_STATUS,
} from '../constant';

export default {
  name: 'TaskDetail',
  components: {
  },
  filters: {
  },
  mixins: [loadingMixin],
  data() {
    return {
      StoreInfo,
      TABLECOLUMN,
      IMPORTANT_ENUM,
      IMPORTANT_ENUM_VALUE,
      StoreData: {
      },
      tableData: [],
      visible: false,
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      loading: {
        loadSubtasksPage: false,
      },
    };
  },
  created() {
  },
  methods: {
    showDetail(rowData) {
      this.StoreData = rowData;
      this.visible = true;
      this.loadSubtasksPage();
    },
    handleClose() {
      this.visible = false;
    },
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
      this.loadSubtasksPage();
    },
    handleSpanMethod({
      columnIndex, row,
    }) {
      let mergeCol = 0;
      if (BIZ_TYPE_VALUE_ENUM.DELIVER === this.StoreData.bizType) {
        mergeCol = 5;
      }
      if (BIZ_TYPE_VALUE_ENUM.DELIVER === this.StoreData.bizType) {
        mergeCol = 6;
      }
      if (columnIndex >= 0 && columnIndex <= mergeCol) {
        if (row.rowspan) {
          return [row.rowspan, 1];
        }

        return [0, 0];
      }

      return [1, 1];
    },
    async loadSubtasksPage() {
      const result = await subtasksPage(this.pagination, { taskId: this.StoreData.id });
      this.pagination.total = result.total;
      this.pagination.page = result.page;
      this.pagination.size = result.size;
      const waitUseTableData = [];
      const waitForTableData = result.records;
      // 将子类中的字段命名为新字段，防止 子类与父类中存在同名字段重复问题
      const fieldTransform = (item, prefix) => {
        const newItem = {};
        Object.keys(item).forEach((key) => {
          newItem[`${prefix}-${key}`] = item[key];
        });
        return newItem;
      };
      waitForTableData.forEach((f) => {
        if (f.subtaskRecords && f.subtaskRecords.length > 0) {
          const rowspan = f.subtaskRecords.length || 0;
          f.subtaskRecords.forEach((c, cIndex) => {
            waitUseTableData.push({
              ...f,
              ...fieldTransform(c, 'subtaskRecords'),
              rowspan: cIndex === 0 ? rowspan : undefined,
              colspan: 1,
            });
          });
        } else {
          waitUseTableData.push({
            ...f,
            rowspan: 1,
            colspan: 1,
          });
        }
      });
      this.tableData = waitUseTableData.map((item) => {
        const newItem = item;
        if (item.subtaskStatus === SUBTASK_STATUS[1].value
        || item.subtaskStatus === SUBTASK_STATUS[2].value) {
          newItem.diffAmount = (item.completedAmount * 1000 - item.amount * 1000) / 1000;
        }
        return newItem;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.details-page {
  min-width: 768px;
}

.block {
  :deep(.el-form-item) {
    margin: 0;
  }
}

.el-col {
  padding-right: 24px;
}

.title {
  border-left: 3px solid #02a7f0;
  padding-left: 10px;

  h3 {
    font-size: 14px;
  }
}

.store_info {
  margin-top: 20px;
}

.body {
  padding-left: 40px;
}

.sub_title {
  margin-top: 20px;
  padding: 4px 32px 4px 4px;
  display: inline-block;
  transform: skew(-25deg); /* 设置倾斜度为-30 */
  -webkit-transform: skew(-25deg);
  -moz-transform: skew(-25deg);
  -o-transform: skew(-25deg);
  -ms-transform: skew(-25deg);
  background-color: #f2f2f2;

  h4 {
    font-weight: normal;
    transform: skew(25deg);
    -webkit-transform: skew(25deg);
    -moz-transform: skew(25deg);
    -o-transform: skew(25deg);
    -ms-transform: skew(25deg);
  }
}

.task-urgent {
  background-color: rgba(241, 75, 95, 0.97);
  color: white;
  padding: 2px 8px 2px 4px;
  border-radius: 4px;
  margin-left: 4px;
}
</style>
