import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 总任务 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11790
 */
export function taskPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/tasks/page', data, {
    params,
  });
}

/**
 * @description: 修改作业状态
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12108
 */
export function tasksChangeOperationType(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/tasks/change_operation_type', data, {
    params,
  });
}

/**
 * @description: 一键完成
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12110
 */
export function tasksFastOver(params, data) {
  return wmsPlusHttp.get('/warehouse_management_system/tasks/fast_over', data, {
    params,
  });
}

/**
 * @description: 修改重要级别
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12112
 */
export function tasksChangeImportant(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/tasks/change_important', data, {
    params,
  });
}

/**
 * @description: 任务分配员工列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12210
 */
export function tasksListUser(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/tasks/list/user', data, {
    params,
  });
}

/**
 * @description: 任务分配
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12212
 */
export function tasksAllot(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/tasks/allot', data, {
    params,
  });
}

/**
 * @description: 子任务详情 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11780
 */
export function subtasksPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/subtasks/page', data, {
    params,
  });
}
