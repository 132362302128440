import {
  TASK_TYPE_ENUM,
  BIZ_TYPE_ENUM,
  BIZ_TYPE_VALUE_ENUM,
  SUBTASK_STATUS_ENUM,
  TEMPERATURE_LAYER,
} from '../constant';
import { timeFormat } from '../utils';

export const StoreInfo = [{
  label: '任务单号:',
  prop: 'taskNo',
}, {
  label: '任务类型:',
  prop: 'taskType',
  formatter: (cellValue) => TASK_TYPE_ENUM[cellValue],
}, {
  label: '来源单据类型:',
  prop: 'bizType',
  formatter: (cellValue) => BIZ_TYPE_ENUM[cellValue],
}, {
  label: '来源单据号:',
  prop: 'bizNo',
}, {
  label: '任务创建时间:',
  prop: 'createTime',
  formatter: (cellValue) => timeFormat(cellValue),
}, {
  label: '作业人:',
  prop: 'operatorName',
}, {
  label: '分配/领取时间:',
  prop: 'receiveTime',
  formatter: (cellValue) => timeFormat(cellValue),
}, {
  label: '作业开始时间:',
  prop: 'startTime',
  formatter: (cellValue) => timeFormat(cellValue),
}, {
  label: '作业完成时间:',
  prop: 'endTime',
  formatter: (cellValue) => timeFormat(cellValue),
}];
export const TABLECOLUMN = (type) => {
  const beforCol = [{
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    width: 180,
  }, {
    label: '货品名称',
    prop: 'goodsName',
    width: 180,
  }, {
    label: '货主',
    prop: 'shipperName',
    width: 180,
  }, {
    label: '作业状态',
    prop: 'subtaskStatus',
    width: 180,
    ENUM: SUBTASK_STATUS_ENUM,
    formatter: (cellValue) => SUBTASK_STATUS_ENUM[cellValue],
  }];
  const afterCol = [{
    label: '作业人',
    prop: 'subtaskRecords-operatorStaffName',
    width: 180,
  }, {
    label: '作业时间',
    prop: 'subtaskRecords-operationTime',
    width: 180,
    timeFormat,
    formatter: (cellValue) => timeFormat(cellValue),
  }];
  const COLS_ENUM = {
    // 波次下架
    [BIZ_TYPE_VALUE_ENUM.WAVE]: [...beforCol, {
      label: '分配批次号',
      prop: 'batchNo',
      width: 180,
    }, {
      label: '分配库位',
      prop: 'unloadStorehouseCode',
      width: 180,
    }, {
      label: '分配数量',
      prop: 'amount',
      width: 180,
    }, {
      label: '实际下架库位',
      prop: 'subtaskRecords-unloadStorehouseCode',
      width: 180,
    }, {
      label: '实际下架批次号',
      prop: 'subtaskRecords-batchNo',
      width: 180,
    }, {
      label: '实际下架量',
      prop: 'subtaskRecords-amount',
      width: 180,
    }, {
      label: '下架容器',
      prop: 'subtaskRecords-containerCode',
      width: 180,
    }, {
      label: '容器暂存位',
      prop: 'subtaskRecords-tsStorehouseCode',
      width: 180,
    }, ...afterCol],
    // 移库
    [BIZ_TYPE_VALUE_ENUM.MOVE]: [...beforCol, {
      label: '分配批次号',
      prop: 'batchNo',
      width: 180,
    }, {
      label: '分配库位',
      prop: 'unloadStorehouseCode',
      width: 180,
    }, {
      label: '推荐上架库位',
      prop: 'onloadStorehouseCode',
      width: 180,
    }, {
      label: '分配数量',
      prop: 'amount',
      width: 180,
    }, {
      label: '实际下架库位',
      prop: 'subtaskRecords-unloadStorehouseCode',
      width: 180,
    }, {
      label: '实际下架批次号',
      prop: 'subtaskRecords-batchNo',
      width: 180,
    }, {
      label: '实际下架量',
      prop: 'subtaskRecords-amount',
      width: 180,
    }, {
      label: '下架容器',
      prop: 'unloadContainerCode',
      width: 180,
    }, {
      label: '实际上架库位',
      prop: 'subtaskRecords-onloadStorehouseCode',
      width: 180,
    }, ...afterCol],
    // 入库
    [BIZ_TYPE_VALUE_ENUM.STORE]: [...beforCol, {
      label: '批次号',
      prop: 'batchNo',
      width: 180,
    }, {
      label: '待上架数量',
      prop: 'unloadAmonunt',
      width: 180,
    }, {
      label: '待上架容器',
      prop: 'unloadContainerCode',
      width: 180,
    }, {
      label: '容器暂存位',
      prop: 'onloadStorehouseCode',
      width: 180,
    }, {
      label: '推荐上架库位',
      prop: 'subtaskRecords-unloadStorehouseCode',
      width: 180,
    }, {
      label: '实际上架库位',
      prop: 'subtaskRecords-onloadStorehouseCode',
      width: 180,
    }, ...afterCol],
    // 盘点
    [BIZ_TYPE_VALUE_ENUM.COUNT]: [{
      label: '库位',
      prop: 'unloadStorehouseCode',
      width: 180,
    }, {
      label: '货主货品编码',
      prop: 'shipperGoodsCode',
      width: 180,
    }, {
      label: '货品名称',
      prop: 'goodsName',
      width: 180,
    }, {
      label: '货主',
      prop: 'shipperName',
      width: 180,
    }, {
      label: '温层',
      prop: 'temperatureLayer',
      width: 80,
      formatter: (cellValue) => TEMPERATURE_LAYER[cellValue],
    }, {
      label: '批次号',
      prop: 'batchNo',
      width: 180,
    }, {
      label: '系统库存量',
      prop: 'amount',
      width: 120,
    }, {
      label: '盘点量',
      prop: 'completedAmount',
      width: 100,
    },
    {
      label: '差异量',
      prop: 'diffAmount',
      width: 100,
    },
    {
      label: '系统托盘码',
      prop: 'unloadContainerCode',
      width: 180,
    },
    {
      label: '盘点托盘码',
      prop: 'subtaskRecords-containerCode',
      width: 180,
    }, ...afterCol],
  };
  return COLS_ENUM[type] ? COLS_ENUM[type] : [...beforCol];
};
